import { defineComponent } from 'vue';
import { TxtType } from 'components/Txt';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';

const AgreementPrivacy = defineComponent({
  setup() {
    return {
      TxtType,
      Size,
      IconType,
      IconName,
    };
  },
});

export default AgreementPrivacy;
