import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { usePages } from 'composables/pages';
import EditModal from 'views/Main/components/EditModal';
import { RouteNames } from 'router/names';

const Agreement = defineComponent({
  name: RouteNames.Agreement,
  components: {
    EditModal,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const { loading, title, content, fetchPageContent } = usePages('agreement');
    fetchPageContent();

    onBeforeMount(() => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });

    function goBack() {
      router.back();
    }

    return {
      t,

      loading,
      title,
      content,
      fetchPageContent,

      goBack,
    };
  },

  data: () => ({
    Size,
    IconName,
    IconType,
  }),
});

export default Agreement;
