<template>
  <main class="agreement">
    <div class="agreement-header">
      <h4>{{ $t('pages.agreementPrivacy.title') }}</h4>
      <icon-btn
        :size="Size.M"
        @click="$router.back()"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>

    <div class="title-wrapper">
      <txt :type="TxtType.Heading1">
        Политика конфиденциальности сайта Lovista.ru
      </txt>
    </div>

    <div>
      <p>
        Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей
        информации, которую веб-сайт «Lovista.ru», расположенный по адресу
        <a href="https://www.lovista.ru/">https://www.lovista.ru/</a>  в сети Интернет,
        (далее Сайт) может получить о пользователе во время использования им Сайта.
      </p>
      <p>
        Использование Сайта означает безоговорочное согласие пользователя с настоящей Политикой и указанными в ней
        условиями обработки его персональной информации; в случае несогласия с этими условиями пользователь должен
        воздержаться от использования сервисов Сайта.
      </p>
      <p class="text-center">
        1. Термины и определения
      </p>

      <p>
        1.1. Конфиденциальность данных — обязательное для соблюдения Администрацией или уполномоченными им лицами,
        получившими доступ к данным, требование не допускать их распространения без согласия субъекта или иного
        законного основания.
      </p>

      <p>
        1.2. Обработка данных – любое действие (операция) или совокупность действий (операций), совершаемых с данными,
        включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение данных.
      </p>

      <p>
        1.3. Администрация – владелец сайта Lovista.ru
      </p>

      <p>
        1.4. Пользователь Сервиса – физическое лицо, являющееся стороной пользовательского соглашения с Администрацией по
        поводу оказания услуг по предоставлению доступа и использования Сервиса как через Интернет-сайт Lovista.ru, так и
        с помощью мобильных приложений, приложений в социальных сетях, а также иным способом, предусмотренным
        Пользовательским соглашением.
      </p>

      <p>
        <b>
          Оказывая услуги по использованию, Администрация, действуя разумно и добросовестно, считает, что Пользователь:
        </b>
      </p>

      <ul>
        <li>
          обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию и использовать настоящий сервис;
        </li>
        <li>
          <b>является совершеннолетним;</b>
        </li>
        <li>
          указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами Сервиса и Сайта,
          включая город фактического проживания;
        </li>
        <li>
          осознает, что информация, размещаемая Пользователем о себе в Сервисе, может становиться доступной для других
          Пользователей Сервиса и пользователей Интернета, может быть скопирована и распространена такими пользователями;
        </li>
        <li>
          осознает, что некоторые виды информации, переданные им другим Пользователям, не могут быть удалены самим
          Пользователем;
        </li>
        <li>
          <b>
            осознает, что сервис предоставляет только площадку для общения Пользователей и не несет ответственности
            за взаимодействия между Пользователями как внутри Сервиса, так и за его пределами. В то же время,
            Администрация вправе накладывать санкции и ограничения (вплоть до удаления анкеты Пользователя) за
            нарушение установленных правил пользования Сервисом, прописанных в Пользовательском Соглашении и Политике;
          </b>
        </li>
        <li>
          <b>
            ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в Политике
            права и обязанности.
          </b>
        </li>
      </ul>

      <p>
        1.5. Пользовательское соглашение – договор, заключаемый между Администрацией и Пользователем по поводу условий
        использования Сервиса. Условия Пользовательского соглашения доступны в сети Интернет по адресу:
        <a href="http://Lovista.ru/agreement/">http://Lovista.ru/agreement/</a>.
      </p>

      <p>
        1.6. Сервис – Интернет-ресурс, представляющий собой совокупность информации и программ для ЭВМ, содержащихся
        в программно-аппаратной системе, обеспечивающей доступность такой информации в сети Интернет по адресу:
        <a href="http://Lovista.ru">http://Lovista.ru</a>.
      </p>

      <p class="text-center">
        2. Состав данных, обрабатываемых Администрацией
      </p>

      <p>
        2.1. В состав данных пользователя, обрабатываемых Администрацией, входит следующая информация о Пользователях
        Сервиса: имя, дата рождения, город проживания, семейное положение, пол, адрес электронной почты, фотография,
        иная информация, которую Пользователи Сервиса добровольно и в определенном ими объеме сообщают о себе или делают
        доступной для Обработки (IP адрес, информация из cookie, информация о браузере Пользователя Сервиса или иной
        программе, с помощью которой осуществляется доступ к Сервису, время доступа, адрес запрашиваемой страницы)
        при использовании Сервиса, если ее обработка не запрещена законодательством Российской Федерации. При этом
        Пользователь Сервиса, по своему усмотрению, может задать такие настройки используемого им устройства для
        доступа к Сервису, которые минимизируют передачу Администрации данных о себе (например, отключить cookie),
        при условии соблюдения настоящей Политики конфиденциальности.
      </p>

      <p>
        2.2. Администрация собирает и хранит только те данные, которые необходимы для предоставления доступа к Сервису
        и оказания услуг в соответствии с Пользовательским соглашением. Администрация не собирает никакую информацию,
        не производит маркетинговых исследований и не рекламирует Услуги Сервиса лицам, не достигшим совершеннолетия.
        В случае выявления регистрации лица, не достигшего совершеннолетия, на Сайте и передачи им/ей своих персональных
        данных, немедленно принимаются меры по удалению учетной записи и персональных данных таких Пользователей.
        <b>
          Администрация также оставляет за собой право блокировки аккаунта в случае выявления дублирования учетной
          записи. Любой Пользователь, уличенный в попытке нарушения или нарушивший- (ая) Политику и/или
          Пользовательское Соглашение вносится в черный список.
        </b>
        Администрация оставляет за собой право производить расследование и/или принимать меры
        юридического реагирования по всем выявленным случаям подобных нарушений, в соответствии с Политикой,
        Пользовательским Соглашением и нормативно-правовыми актами страны фактического проживания Пользователя.
      </p>

      <p class="text-center">
        3. Цель обработки данных пользователя
      </p>

      <p>
        3.1. Администрация осуществляет обработку данных пользователя исключительно в целях:
      </p>
      <p>
        3.1.1 оказания услуг по предоставлению доступа к Сервису и возможности его использования Пользователями Сервис
        а, в том числе при идентификации Пользователя Сервиса, оказании информационных услуг, таргетировании рекламных
        материалов, совершенствовании Сервиса и разработке новых услуг;
      </p>

      <p>
        3.1.2 заключения с Пользователями Сервиса иных сделок, не запрещенных законодательством Российской Федерации, и
        осуществления действий, направленных и/или связанных с исполнением таких сделок; а также рекламирования и
        продвижения на рынке товаров, работ, услуг, представляемых Администрацией посредством Сервиса;
      </p>

      <p>
        3.1.3 соблюдения законодательства Российской Федерации.
      </p>

      <p class="text-center">
        4. Порядок обработки данных пользователя
      </p>

      <p>
        4.1. Обработка данных пользователя осуществляется в следующих случаях:
      </p>
      <p>
        4.1.1. обработка данных пользователя осуществляется с согласия Пользователя Сервиса на обработку его данных;
      </p>

      <p>
        4.1.2. обработка данных пользователя необходима для заключения и исполнения договора, стороной которого является
        Пользователь Сервиса;
      </p>

      <p>
        4.1.3. осуществляется обработка данных пользователя, доступ неограниченного круга лиц к которым предоставлен
        Пользователем Сервиса либо по его просьбе;
      </p>

      <p>
        4.1.4. в иных случаях, предусмотренных законодательством Российской Федерации.
      </p>
      <p>
        4.2. Администрация обеспечивает возможность использования Сервиса только при условии предварительного
        предоставления Пользователем Сервиса добровольного и информированного согласия об обработке
        его данных в соответствии с настоящей Политикой конфиденциальности.
      </p>

      <p>
        4.3. При использовании Сервиса Пользователь обязуется действовать добросовестно, при этом добросовестность
        Пользователя Сервиса предполагается в силу законодательства Российской Федерации. Пользователь Сервиса обязан
        предоставлять только достоверную информацию о себе. Пользователь Сервиса несет ответственность за достоверность,
        актуальность и соответствие законодательству Российской Федерации предоставленной им информации и риск нарушения
        своими действиями прав третьих лиц.
      </p>

      <p>
        4.4. Пользователь Сервиса соглашается с тем, что Администрация может направлять на электронный адрес
        Пользователя Сервиса, указанный при регистрации, информационные, финансовые, системные и иные уведомления.
        При этом Пользователь Сервиса после осуществления регистрации может самостоятельно настроить перечень
        информации, которую он желает получать от Администрации, путем редактирования соответствующего раздела Сервиса.
      </p>

      <p>
        4.5. Пользователь Сервиса вправе отозвать согласие на обработку его данных путем самостоятельного удаления своей
        учетной записи (аккаунта) в Сервисе и прекращения использования Сервиса.
      </p>

      <p>
        4.6. В случае отзыва Пользователем Сервиса согласия на обработку его данных Администрация прекращает обработку
        указанных данных и уничтожает их в срок,
        <b>не превышающий 180 (сто восемьдесят) дней</b> с даты получения
        Администрацией соответствующего отзыва, в порядке и на условиях, предусмотренных законодательством
        Российской Федерации и Пользовательским соглашением.
      </p>

      <p class="text-center">
        5. Права Пользователей Сервиса
      </p>

      <p>
        5.1. Пользователи Сервиса имеют право:
      </p>

      <p>
        <b>
          5.1.1. на регистрацию не более одного аккаунта при использовании Сервиса;
        </b>
      </p>
      <p>
        5.1.1. на свободный и безвозмездный доступ к своим данным в порядке и пределах, предусмотренных
        законодательством Российской Федерации;
      </p>

      <p>
        5.1.2. на информацию о своих данных и их обработке, предоставляемую в объеме и порядке, установленном
        законодательством Российской Федерации;
      </p>
      <p>
        5.1.3. требовать исключения и/или исправлении недостоверных и неполных данных, а также данных, обработанных с
        нарушением положений законодательства Российской Федерации, при условии, что Пользователь Сервиса или его
        уполномоченный представитель представит сведения, подтверждающие, что данные, которые относятся к
        соответствующему субъекту и обработку которых осуществляет Администрация, являются неполными,
        устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
      </p>

      <p>
        5.1.4. обжаловать в установленном порядке в суде любые неправомерные действия и/или бездействие лиц,
        уполномоченных на обработку данных и ответственных за организацию защиты данных;
      </p>

      <p>
        5.1.5. осуществлять иные предусмотренные законодательством Российской Федерации права в отношении своих данных.
      </p>
      <p class="text-center">
        6. Обеспечение безопасности данных
      </p>

      <p>
        6.1. Администрация предпринимает необходимые правовые, организационные и технические меры для обеспечения
        безопасности данных Пользователей Сервиса от случайного или несанкционированного доступа, уничтожения,
        изменения, блокирования доступа и других неправомерных действий в отношении данных Пользователей Сервиса.
      </p>
      <p>
        6.2. В целях координации действий по обеспечению безопасности данных Пользователей Сервиса Администрация:
      </p>
      <p>
        6.2.1. принимает локальные нормативные акты, направленные на планирование, организацию и реализацию правовых,
        организационных и технических мер для обеспечения безопасности данных Пользователей Сервиса, а также в полном
        объеме исполняет указанные меры;
      </p>
      <p>
        6.2.2. назначает лицо, ответственное за обеспечение безопасности данных.
      </p>

      <p class="text-center">
        7. Заключительные положения
      </p>

      <p>
        7.1. Настоящая Политика конфиденциальности является общедоступным документом, декларирующим концептуальные
        основы деятельности Администрации при обработке данных Пользователей Сервиса.
      </p>

      <p>
        7.2. Администрация имеет право вносить изменения в настоящую Политику конфиденциальности. Новая редакция
        Политики конфиденциальности вступает в силу с момента ее размещения в сети Интернет по адресу
        <a href="http://Lovista.ru/agreement/privacy/">http://Lovista.ru/agreement/privacy/</a>,
        если иное не предусмотрено Администрацией. Действующая редакция Политики
        конфиденциальности всегда доступна в сети Интернет по адресу
        <a href="http://Lovista.ru/agreement/privacy/">http://Lovista.ru/agreement/privacy/</a>.
      </p>

      <p>
        7.3. Администрация информирует Пользователей Сервиса, что настоящая Политика конфиденциальности применима только
        к Сервису. Администрация не контролирует и не несет ответственность за использование сайтов третьих лиц, на
        которые Пользователь может по своему усмотрению и на свой риск перейти по ссылкам, размещенным посредством
        Сервиса другими Пользователями Сервиса.
      </p>

      <p>
        7.4. К отношениям между Пользователями Сервиса и Администрацией, возникающими в связи с применением настоящей
        Политики конфиденциальности, подлежит применению право Российской Федерации.
      </p>

      <p>
        7.5. В тех случаях, когда Администрация для удобства ознакомления Пользователями с условиями настоящей Политики
        конфиденциальности предоставляет ее перевод на другой язык, версия Политики конфиденциальности на русском языке
        будет иметь преимущественное значение, и отношения между Пользователем и Администрацией будут регулироваться
        версией Политики конфиденциальности на русском языке.
      </p>

      <p>
        Дата последней редакции: 25.08.2022
      </p>
    </div>
  </main>
</template>

<script lang="ts" src="./AgreementPrivacy.ts" />
