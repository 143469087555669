<template>
  <main class="agreement">
    <div class="agreement-header">
      <h4>{{ t('pages.agreement.title') }}</h4>
      <icon-btn
        :size="Size.M"
        @click="goBack"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>

    <div class="title-wrapper">
      <h1 v-html="title" />
      <edit-modal
        page-name="agreement"
        @update="fetchPageContent"
      />
    </div>

    <div v-html="content" />
  </main>
</template>

<script lang="ts" src="./Agreement.ts" />
